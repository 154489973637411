@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Public+Sans:wght@400;600&family=Inter:wght@600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .wrapper {
    @apply flex w-full justify-start items-center flex-col;
  }
  .contain {
    @apply flex w-[90%] max-w-[1100px];
  } 
  .title {
    @apply text-[30px] sm:text-[40px] lg:text-[52px] font-semibold;
  }
  .input {
    @apply text-gray text-sm font-normal border-none outline-none rounded-[5px] bg-[#f6f6f6] w-full h-[30px];
  }
  .testimonial-heading {
    @apply bg-brownGr text-transparent bg-clip-text font-semibold;
  }
  .center-slides li {
    @apply flex justify-center items-center;
  }
  body {
    @apply bg-white;
  }
  .titleBig {
    @apply text-[30px] sm:text-[40px] lg:text-[64px] text-white font-normal leading-[1.3];
  }
  .textSmall {
    @apply text-base sm:text-xl font-normal;
  }
}
html {
  scroll-behavior: smooth;
}

/* Mobile styles */
@media (max-width: 768px) {
  .invoice-parent-container {
    /* Add any specific styles for the container on mobile screens */
    height: auto;
  }

  .invoice-parent-container table {
    /* Adjust table styles on mobile screens */
    width: 100%;
  }

  .invoice-parent-container th,
  .invoice-parent-container td {
    /* Adjust table cell styles on mobile screens */
    text-align: left;
  }

  .invoice-parent-container .flex {
    /* Adjust flex layout on mobile screens */
    flex-direction: column;
  }

  .invoice-parent-container .text-2xl {
    /* Adjust font size on mobile screens */
    font-size: 1.5rem;
  }

  /* Add any additional mobile styles as needed */
}
.table-responsive {
  max-width: 100vw; /* Set the max width to be the width of the window */
}